<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status','goods_logo','goods_put']"
			:submit_preprocessing="submit_preprocessing"
			:is_created_get_list="false"
            ref="list"
        >
            <template slot="left_btn">
				
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加商品</a-button>
				 <a-button class="float_left" v-auth="'change'" @click="Synchronize()" type="primary">同步商品</a-button>
				<a-button class="float_left" v-auth="'change'" @click="max_price_visible = true">商品预警价格</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.text=='1'">上架</span>
                <span v-else-if="data.text=='0'">下架</span>
            </template>

            <template slot="goods_logo" slot-scope="data">
                <img v-if="data.text" style="width:50px; height:50px" :src="data.text" alt />
                <img v-else style="width:50px; height:50px" src="@/assets/img/null_img.png" alt />
            </template>

            <template slot="goods_put" slot-scope="data">
                <span v-if="data.text=='1'">是</span>
                <span v-else-if="data.text=='0'">否</span>
            </template>

            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                </span>
                <a-divider type="vertical" />
                <span>
                    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
                        <a>删除</a>
                    </a-popconfirm>
                </span>
            </template>
        </TableList>
        <EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
			<template slot="max_price_tips">
				<div class="max_price_tips">注：业代下单时可输入的最高单价，超过规则不允许提交订单</div>
			</template>
		</EditPop>
		
		<EditPop @handleSubmit="max_price_submit" :form_data="max_price_form_data" :visible.sync="max_price_visible">
			<template slot="content">
				<div class="max_price_form">
					<div class="item">
						<div class="label">设置方式：</div>
						<div class="right">
							<a-radio-group v-model:value="max_price_form.type">
								<a-radio value="alone">单独设置</a-radio>
								<a-radio value="unified">批量设置</a-radio>
							</a-radio-group>
						</div>
					</div>
					
					<div class="item" v-show="max_price_form.type === 'unified'">
						<div class="label">批量价格：</div>
						<div class="right">
							<a-input-number class="input_number" v-model:value="max_price_form.max_price" placeholder="请输入批量价格"/>
						</div>
					</div>
				</div>
			</template>
		</EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getGoodsList, modifyGoods, goodsDelete, goodsCate, getSetting, saveSetting,syncGoodsInfo } from "@/api/goods";

let columns = [
    {
        title: "商品图片",
        dataIndex: "goods_logo",
        scopedSlots: {
            customRender: "goods_logo"
        }
    },
    {
        title: "货物ID",
        dataIndex: "sap_id",
    },
    {
        title: "商品条码",
        dataIndex: "goods_code",
    },
    {
        title: "商品分类",
        dataIndex: "cate_path",
    },
    {
        title: "商品名称",
        dataIndex: "goods_title",
    },
	
    {
        title: "单位",
        dataIndex: "goods_spec",
        scopedSlots: {
            customRender: "goods_num"
        }
    },
    {
        title: "销售价",
        dataIndex: "goods_price",
    },
    {
        title: "排序",
        dataIndex: "sort",
    },
    {
        title: "费用投放",
        dataIndex: "goods_put",
        scopedSlots: {
            customRender: "goods_put"
        }
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "gpoodIndex",
    components: {
        EditPop,
        Form,
        TableList,
    },
    data() {
        return {
            get_table_list: getGoodsList,
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            columns,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "title",
                        title: "商品",
                        placeholder: '商品名称/条形码',
                        options: {

                        }
                    },

                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                    {
                        type: 'select',
                        name: 'put',
                        title: '费用投放',
                        list: [
                            ...this.$config.status_all,
                            {
                                key: 1,
                                value: "是"
                            },
                            {
                                key: 0,
                                value: "否"
                            }
                        ],
                        options: {},
                    },
					{
					    type: 'tree-select',
					    name: 'cate_id',
						placeholder:'请选择商品分类',
					    title: '商品分类',
						treeData:[],
						multiple:true,
						replaceFields:{label:'cate_title',value:'id',children:'children'},
					    options: {
					
					    },
					},
					{
                        type: "tree-select",
                        name: "department_ids",
                        title: "所属区域",
                        options: {},
                        treeData: [],
                        multiple: true
                    },{
                        type: "tree-select",
                        name: "cost_department_ids",
                        title: "费用区域",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
					
					
					
                ],
                ...this.$config.form_data_seo
            },
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'goods_title',
                        title: '商品名称',
                        options: {
							rules: [
							    {
							        required: true,
							        message: "请输入商品名称"
							    }
							]
                        },
					},
					{
                        type: 'text',
                        name: 'abbreviation',
                        title: '商品简称',
                        options: {
                        	rules: [
                        	    {
                        	        required: true,
                        	        message: "请输入商品简称"
                        	    }
                        	]
                        },
                    },
					{
                        type: 'cascader',
                        name: 'parent_path',
						placeholder:'请选择商品分类',
                        title: '商品分类',
						list:[],
						replaceFields:{label:'cate_title',value:'id',children:'children'},
                        options: {
                        	rules: [
                        	    {
                        	        required: true,
                        	        message: "请选择商品分类"
                        	    }
                        	]
                        },
                    },
					
					{
					    type: "tree-select",
					    name: "department_ids",
					    title: "订单区域",
					    options: {
					    	
					    },
					    treeData: [],
					    multiple: true
					},
					{
					    type: "tree-select",
					    name: "cost_department_ids",
					    title: "费用区域",
					    options: {
					    
					    },
					    treeData: [],
					    multiple: true
					},
                    {
					    type: 'text',
					    name: 'sap_id',
					    title: '货物ID',
					    options: {
							rules: [
							    {
							        required: true,
							        message: "请输入货物ID"
							    }
							]
                        },
					},
					{
					    type: 'text',
					    name: 'goods_desc',
					    title: '货物描述',
					    options: {},
					},
                    {
                        type: 'radio',
                        name: 'status',
                        title: '状态',
                        list: [
                            {
                                key: 1,
                                value: "上架"
                            },
                            {
                                key: 0,
                                value: "下架"
                            }
                        ],
                        options: {
                        	
                        },
                    },
                    {
                        type: 'text',
                        name: 'goods_spec',
                        title: '规格',
                        options: {
                        	rules: [
                        	    {
                        	        required: true,
                        	        message: "请输入商品规格"
                        	    }
                        	]
                        },
                    },
                    {
                        type: 'text',
                        name: 'goods_weight',
                        title: '重量(千克)',
                        options: {},
                    },
                    // {
                    //     type: 'text',
                    //     name: 'remarks',
                    //     title: '单位',
                    //     options: {},
                    // },
                    {
                        type: 'text',
                        name: 'sort',
                        title: '排序号',
                        options: {},
                    },
                    // {
                    //     type: 'text',
                    //     name: 'discount_price',
                    //     title: '市场价',
                    //     options: {},
                    // },
                    {
                        type: 'text',
                        name: 'goods_price',
                        title: '销售价',
                        options: {
                        	rules: [
                        	    {
                        	        required: true,
                        	        message: "请输入销售价"
                        	    }
                        	]
                        },
                    },
                    {
                        type: 'text',
                        name: 'max_price',
                        title: '预警价格',
                        options: {},
                    },
					{
						type:'slot',
						name:'max_price_tips',
					},
					{
						type: 'number',
						name: 'conversion',
						title: '换算数量',
						options: {},
					},
                    {
                        type: 'radio',
                        name: 'goods_put',
                        title: '费用投放商品',
                        list: [
                            {
                                key: 1,
                                value: "是"
                            },
                            {
                                key: 0,
                                value: "否"
                            }
                        ],
                        options: {
							
						},
                    },
                    {
                        type: 'upload',
                        name: 'goods_logo',
                        title: '商品图片',
                        options: {},
                    },
                    {
                        type: 'textarea',
                        name: 'remarks',
                        title: '备注',
                        options: {},
                    },
                ]
            },
			max_price_visible:false,// 商品预警价格弹窗是否显示
			max_price_form_data:{
				title:'商品预警价格',
				list:[
					{
						type:'slot',
						name:'content'
					}
				]
			},
			max_price_form:{
				type:'alone', // alone unified
				max_price:0
			}, // 商品预警价格表单中的数据
			submit_preprocessing:{
				array_to_string:['cate_id','department_ids','cost_department_ids']
			}
        };
    },
    async created() {
		this.get_cate_list(); // 获取分类列表（树结构）
		this.get_department(); // 获取部门树（编辑/添加时的所属区域）
		this.get_max_price_setting();// 获取商品预警设置
    },
    methods: {
		// 同步商品 Synchronize
		Synchronize(){
			// 
			syncGoodsInfo({data:{},info:true}).then(res=>{
				this.$refs.list.get_list();
			})
		},
		// 获取商品预警设置
		get_max_price_setting(){
			getSetting().then(res=>{
				this.max_price_form = res.data.data;
			})
		},
		// 保存商品预警设置
		max_price_submit(){
			saveSetting({
				data:{
					data:this.max_price_form,// 我也不想这么做，但因为后端原因我必须在外面包一层data
				},
				info:true
			}).then(res=>{
				this.max_price_visible = false;
			})
		},
		// 获取部门树形数据
		get_department(){
			this.$method.get_department().then(res=>{
				this.$method.set_form_list(this.form_data.list,'department_ids','treeData',res);
				this.$method.set_form_list(this.form_data_seo.list,'department_ids','treeData',res);
				this.$method.set_form_list(this.form_data_seo.list,'cost_department_ids','treeData',res);
				this.$method.set_form_list(this.form_data.list,'cost_department_ids','treeData',res);
				
			})
		},
		// 获取商品分类列表，树形数据结构
		get_cate_list(){
			goodsCate().then(res=>{
				// 为列表筛选项中的商品分类填充数据
				this.$method.set_form_list(this.form_data_seo.list,'cate_id','treeData',res.data.list);
				
				// 如果路由中存在分类ID那么就代表是从商品分类管理页面跳转过来的，需要将分类ID回显至筛选表单中
				if(this.$route.query.cate_id){
					this.form_data_seo.list.forEach(item=>{
						if(item.name == 'cate_id'){
							item.options.initialValue = this.$route.query.cate_id; // UI层面数据填充
							this.$refs.list.fixed_seo_data.cate_id = this.$route.query.cate_id; // 数据层面数据填充
						}
					})
				}
				this.$refs.list.get_list();
				
				// 为添加/编辑商品分类的级联选择器填充数据
				let node_list = [];
				let tree = {
					children:JSON.parse(JSON.stringify(res.data.list)),
				};
				// 添加/编辑商品分类必须选中品号一级，所以需要对数据进行额外处理
				this.deepFirstSearch(tree,node_list,(node,c_item)=>{
					if(c_item.children){
						// 只要不是品号并且他没有子级，那么就将其禁用
						if(!c_item.children.length && c_item.type !== 'number'){
							c_item.disabled = true;
						}
					}
					if(c_item.type === 'number'){
						delete c_item.children;
					}
				})
				this.$method.set_form_list(this.form_data.list,'parent_path','list',tree.children);
			})
		},
		// 深度优先遍历
		deepFirstSearch(node,nodeList = [],fun,first_load = false) {
		    if (node) {
				nodeList.push(node);
		        let children = node.children || [];
		        for (let i = 0; i < children.length; i++){
					//每次递归的时候将 需要遍历的节点 和 节点所存储的数组传下去
					fun(node,children[i]);
					this.deepFirstSearch(children[i],nodeList,fun);    
				}
		    }    
		    return nodeList; // 返回遍历过的节点（深度优先遍历顺序）
		},
        add() {
            this.form_data.title = "添加"
            this.visible = true;
            this.form_data.list.forEach(item => {
                if (item.type == 'upload') {
                    item.options.initialValue = [];
                }else if(item.type == 'tree-select'){
					item.options.initialValue = null; // tree-select组件需要在初始将值设置为null，否则tree-select的输入框会存在内容异常或没有placeholder问题
				}else if(item.type == 'cascader'){
					item.options.initialValue = [];
				}else if(item.type !== 'slot'){
					item.options.initialValue = "";
				}
				
				if(item.name == 'goods_put' || item.name == 'status'){
					item.options.initialValue = 1;
				}
            })
        },
        edit(record) {
            this.form_data.title = "编辑"
            let data = record
            this.form_data.list.forEach(item => {
                if (item.type == 'upload') {
                    let val = {};
                    val[item.name] = {
                        fileList: [{
                            url: data[item.name]
                        }]
                    }
                    item.options.initialValue = data[item.name] ? [{
                        uid: data[item.name],
                        name: data[item.name],
                        status: 'done',
                        url: data[item.name]
                    }] : [];

                } else if(item.type != 'slot') {
                    item.options.initialValue = data[item.name];
                }
            })
            this.visible = true;
        },
        del(record) {
            goodsDelete({
                data: {
                    id: record.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
			if(data.department_ids && data.department_ids.length){
				data.department_ids = data.department_ids.toString();
			}
			if(data.cost_department_ids && data.cost_department_ids.length){
				data.cost_department_ids = data.cost_department_ids.toString();
			}
            modifyGoods({
                data: {
                    ...data
                },
				info:true,
            }).then(res => {
                this.visible = false;
                this.$refs.list.get_list();
            })
        },

    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.max_price_tips{
	margin-left: 99px;
	margin-top: -30px;
	font-size: 13px;
}
.float_left{
	margin-right: 8px;
}
.max_price_form{
	.item{
		display: flex;
		.right{
			flex:1;
			.input_number{
				width: 100%;
			}
		}
	}
}
</style>